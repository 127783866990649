import styled, { css } from "styled-components";
import { prop, ifProp, theme } from "styled-tools";
import { handleColor } from "../../Utilities/Icons";
import { PropsText } from "@models/TextInterface";
import mediaQuery, { Screen } from "@utils/MediaQuery";
import { setTextSize } from "./functions/setTextSize";

export const TextStyled = styled.p<PropsText>`
  color: ${(props) => (props.color ? handleColor(props.color) : theme("Content_UX_01"))};
  font-family: Poppins;
  font-weight: ${ifProp("weight", prop("weight"), "400")};
  ${setTextSize()}
  line-height: ${prop("lineHeight", "normal")};
  text-align: ${ifProp("align", prop("align"), "center")};
  margin: ${prop("margin")};
  text-transform: ${prop("transform")};
  letter-spacing: ${prop("letterSpacing")};
  text-decoration: ${prop("textDecoration")};
  vertical-align: ${prop("verticalAlign")};
  text-transform: ${prop("transform")};

  ${ifProp(
    "gradient",
    css`
      background: ${(props: PropsText) => (props.color ? handleColor(props.color) : theme("Gradient_Rewards"))};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
  )};

  ${ifProp(
    "overflow",
    css`
      text-overflow: ${prop("overflow", "elipsis")};
      max-width: 100%;
      max-height: 21px;
      overflow: hidden;
      white-space: nowrap;
    `
  )}

  ${ifProp(
    "xs",
    css`
      ${mediaQuery[Screen.xs]} {
        ${setTextSize(Screen.xs)};
      }
    `
  )}
  
  ${ifProp(
    "sm",
    css`
      ${mediaQuery[Screen.sm]} {
        ${setTextSize(Screen.sm)};
      }
    `
  )}
  
  ${ifProp(
    "md",
    css`
      ${mediaQuery[Screen.md]} {
        ${setTextSize(Screen.md)};
      }
    `
  )}

  ${ifProp(
    "lg",
    css`
      ${mediaQuery[Screen.lg]} {
        ${setTextSize(Screen.lg)};
      }
    `
  )}

  ${ifProp(
    "xl",
    css`
      ${mediaQuery[Screen.xl]} {
        ${setTextSize(Screen.xl)};
      }
    `
  )}

  ${ifProp(
    "xxl",
    css`
      ${mediaQuery[Screen.xxl]} {
        ${setTextSize(Screen.xxl)};
      }
    `
  )}

  ${ifProp(
    "mobile",
    css`
      ${mediaQuery[Screen.mobile]} {
        ${setTextSize(Screen.mobile)};
      }
    `
  )}

  ${ifProp(
    "desktop",
    css`
      ${mediaQuery[Screen.desktop]} {
        ${setTextSize(Screen.desktop)};
      }
    `
  )}

  ${ifProp(
    "tablet",
    css`
      ${mediaQuery[Screen.tablet]} {
        ${setTextSize(Screen.tablet)};
      }
    `
  )}

  ${ifProp(
    "xlDesktop",
    css`
      ${mediaQuery[Screen.xlDesktop]} {
        ${setTextSize(Screen.xlDesktop)};
      }
    `
  )}
`;

export const SendolaSecondaryColorSpan = styled.span`
  b {
    color: ${theme("Primary_03_100")};
  }
`;

export const SendolaColorSpan = styled.span`
  b {
    color: ${theme("Primary_01")};
  }
`;

export const ZelleColorSpan = styled.span`
  b {
    color: ${theme("Zelle")};
  }
`;

export const VenmoColorSpan = styled.span`
  b {
    color: ${theme("VenmoColor")};
  }
`;

export const SuperscriptStyled = styled(TextStyled)`
  font-size: 60%;
`;
