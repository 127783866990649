export type PaymentOrder = {
  id: string;
  mtn: string;
  mtcn: string;
  paymentReason: string;
  status: string;
  fullName: string;
  alias: string;
  personId: string;
  destinationCountry: string;
  deliveryMethod: string;
  deliveryMethodCode: string;
  destination: string;
  accountNumber: string;
  currencySend: string;
  amountSend: number;
  amountReceived: number;
  currencyReceived: string;
  fx: number;
  amountFound: number;
  processedAt: string;
  transactionId?: string;
  contactName: string;
};

export enum PaymentOrderStatus {
  settled = "settled",
  pendingRail = "pending to be processed by the rail",
  pendingSendingByRail = "pending sending to rail",
  acceptedByRail = "accepted by rail",
  cancelled = "cancelled",
  inReview = "in review",
  availableToBePay = "available to be pay",
  created = "created",
  pending = "pending",
  funds = "funds",
  verified = "verified",
  accepted = "accepted",
  review = "review",
  available = "available for payment",
  rejected = "rejected",
  canceled = "canceled",
  paid = "paid",
  declined = "declined",
  notPaid = "not paid",
}

export interface PaymentOrderVerifications {
  showTermsAndConditions: boolean;
  senderEmail: string;
}

export interface PaymentOrderSenderEmail {
  id?: string;
  email: string;
}

export const PaymentOrdersSortByDate = (paymentOrders: PaymentOrder[]) => {
  return paymentOrders
    .map((order) => {
      return { ...order, date: new Date(order.processedAt) };
    })
    .sort((a, b) => Number(a.processedAt) - Number(b.processedAt))
    .slice(0, 3);
};

export interface PaymentTransactions {
  AmountFound?: string;
  AmountRecived?: string;
  AmountSendMax?: string;
  AmountSendMin?: string;
  ContactName?: string;
  CurrencyRecived?: string;
  CurrencySend?: string;
  Destination?: string;
  PageNumber?: number;
  PageSize?: number;
  PaymentReason?: string;
  Rail?: string;
  SortBy?: string;
}

export interface PaymentTransactionHistory {
  count: number;
  pageNumber: number;
  pageSize: number;
  total: number;
  results: TransactionList[];
}

export interface PaymentTransactionHistoryAll {
  count: number;
  pageNumber: number;
  pageSize: number;
  total: number;
  results: TransactionListDate[];
}

export interface TransactionListDate {
  transactions: TransactionList[];
}

export interface TransactionList {
  accountNumber: string;
  amountFound: number;
  amountReceived: number;
  amountSend: number;
  contactName: string;
  countrySender: string;
  currencyReceived: string;
  currencySend: string;
  deliveryMethod: string;
  deliveryMethodCode: string;
  destination: string;
  destinationCountry: string;
  fundType: string;
  fx: number;
  mtcn: string;
  mtn: string;
  paymentReason: string;
  processedAt: string;
  rail: string;
  sender: string;
  status: string;
  transactionId: string;
  userId: string;
  visible?: boolean;
  month?: string;
}

export interface TransactionHistory {
  data: TransactionList;
  getDetails?: () => void;
  date: string;
}

export interface PaymentOrderDetailResponse {
  id: string;
  deliveryMethod: string;
  mtcn: string;
  mtn: string;
  orderedAt: string;
  receipt: string;
  rail: string;
  lastStatus: string;
  payment: {
    amount: number;
    authCode: string;
    bin: string | null;
    cardType: string;
    last4: string | null;
    name: string;
    paymentRequestId: string;
    processedOn: string;
    productType: string;
    status: string;
  };
  instruction: {
    origination: {
      country?: string;
      currency: string;
      countrySubdivision?: string;
      amount: number;
    };
    destination: {
      country: string;
      destinationId: string;
      destinationName: string;
      accountNumber: string;
      currency: string;
      amount: number;
      countrySubdivision?: string;
    };
    quote: {
      type: string;
      fee: number;
      paymentMethodFee?: number;
      fx: number;
      spread?: number;
      quoteUpdatedAt: string;
    };
  };
  receiver: {
    firstName: string;
    lastName: string;
    middleName: string;
  };
}
