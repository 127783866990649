import { FlexContainer } from "@components/Flex/Flex.styled";
import mediaQuery, { Screen } from "@utils/MediaQuery";
import styled from "styled-components";

export const VerifyKYCBannerCard = styled(FlexContainer)`
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  font-family: "Poppins";
  height: auto;
  max-width: 100%;
  padding: 9px 21px;
  width: 100%;
  margin: 24px 0 0 0;
  align-items: center;

  img {
    width: 73px;
    margin-right: 24px;

    ${mediaQuery[Screen.desktop]} {
      width: 20%;
    }
  }
`;
