import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CardDetails,
  ContactDetails,
  AmountContainer,
  ContainerCard,
  TextContainer,
  IconCountry,
  StatusText,
} from "./TransactionsCardPayments.styled";

import { Column, Row } from "@components/Grid";
import Text from "@components/Text";
import { PaymentOrderStatus, TransactionHistory } from "@core/Payments/PaymentOrders/domain/PaymentOrder";
import { formatNumber } from "@utils/NumberUtils";
import { countrySelector } from "@redux/Payments/Country/Country.slice";
import { ExchangeRate } from "@core/Payments/Common/domain/ExchangeRates";
import { useSelector } from "react-redux";
import { FlexContainer } from "@components/Flex/Flex.styled";

const TransactionsCardPayments = ({ data, getDetails, date }: TransactionHistory) => {
  const { t } = useTranslation("global");
  const countries = useSelector(countrySelector).countries as ExchangeRate[];

  const transactionStatuses: { [key: string]: string } = {
    [PaymentOrderStatus.settled]: t("TransactionStatuses.Paid"),
    [PaymentOrderStatus.inReview]: t("TransactionStatuses.InReview"),
    [PaymentOrderStatus.acceptedByRail]: t("TransactionStatuses.AcceptedByRail"),
    [PaymentOrderStatus.pending]: t("TransactionStatuses.Pending"),
    [PaymentOrderStatus.funds]: t("TransactionStatuses.Funds"),
    [PaymentOrderStatus.verified]: t("TransactionStatuses.Verified"),
    [PaymentOrderStatus.accepted]: t("TransactionStatuses.AcceptedByRail"),
    [PaymentOrderStatus.review]: t("TransactionStatuses.InReview"),
    [PaymentOrderStatus.available]: t("TransactionStatuses.Available"),
    [PaymentOrderStatus.rejected]: t("TransactionStatuses.Rejected"),
    [PaymentOrderStatus.cancelled]: t("TransactionStatuses.Cancelled"),
    [PaymentOrderStatus.canceled]: t("TransactionStatuses.Cancelled"),
    [PaymentOrderStatus.paid]: t("TransactionStatuses.Paid"),
    [PaymentOrderStatus.declined]: t("TransactionStatuses.Declined"),
    [PaymentOrderStatus.created]: t("TransactionStatuses.Created"),
    [PaymentOrderStatus.notPaid]: t("TransactionStatuses.NotPaid"),
  };

  const statusText = useMemo(() => {
    const status = data?.status?.toLowerCase() || "";
    return transactionStatuses?.[status] || "";
  }, [data?.status]);

  return (
    <FlexContainer as={CardDetails} onClick={getDetails}>
      <Row>
        <Column span={12}>
          <ContainerCard>
            <TextContainer>
              <IconCountry>
                <img src={countries.find((exchange) => exchange.countryCode === data.destinationCountry)?.iconUs} />
              </IconCountry>
              <ContactDetails>
                <Text size={0.5} align="left" color="black" weight={500} margin="0 0 4px 0">
                  {data.contactName}
                </Text>
                <Text size={"default"} align="left" color="grey" weight={400} margin="0 0 4px 0">
                  {data.deliveryMethodCode === "D2B"
                    ? t("ReviewTransaction.PayOutMethod.DirectToBank")
                    : t("ReviewTransaction.PayOutMethod.CashPickUp")}
                </Text>
                <StatusText status={data.status}>
                  {date} - <span>{statusText}</span>
                </StatusText>
              </ContactDetails>
            </TextContainer>
            <AmountContainer>
              <Text size={0.5} color="black_highlight" weight={600} margin="0 0 4px 0" align="right">
                {formatNumber(data.amountFound || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                {data.currencySend}
              </Text>
              <Text size={"default"} color="grey" weight={400} margin="0 0 4px 0" align="right">
                {formatNumber(data.amountReceived || 0, {
                  minimumFractionDigits: 2,
                })}{" "}
                {data.currencyReceived}
              </Text>
            </AmountContainer>
          </ContainerCard>
        </Column>
      </Row>
    </FlexContainer>
  );
};

export default TransactionsCardPayments;
