import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Select, { PropsValue } from "react-select";

// Own redux
import { AppDispatch } from "../../Redux/Store";
import { selectorLanguage } from "../../Redux/Translate";
import { getLocationsGPAction } from "../../Redux/LocationsGP/LocationsGP.actions";

// Own constants
import { LOCATIONS_GP_FULFILLED, LOCATIONS_GP_REJECTED } from "../../Constants/Locations";

// Own models
import { AlignItems, JustifyContent } from "../../Models/Column";
import { OptionCPUCountry, OptionD2BCountry } from "../../Models/LocationsGP";
import { DestinationOption, QuoterProps } from "../../Models/Quote";
import { SelectOption } from "../../Models/Select";
// Own utils
import { formatNumber } from "../../Utilities/NumberUtils";
// Own components
import { Column, Row } from "../Grid";
import Icon from "../Icon";
import Text from "../Text";
import SwitchButton from "../SwithButton";
import Button from "../Button";
import Skeleton from "../Skeleton";
import CurrencyInput from "../CurrencyInput";
import { SearchSelect } from "../SearchSelect/SearchSelect.component";
// Own styles
import {
  DivisorLine,
  GlobalPaymentIconRounded,
  NotEmptyOptions,
  QuoterCard,
  SectionFlag,
  SectionFlagWithSelect,
  SectionRecipientGets,
  marginBottomContainer,
  CustomText,
  InfoMessage,
  marginVerticalContainer,
  ViewLegalInformationLink,
} from "./GlobalPaymentQuoter.styled";
import { currencySelectRTLStyles, selectStylesGlobalPayments } from "../ReactSelect/ReactSelect.styled";
import DarkTooltip from "../DarkTooltip";
import { CPU, D2B } from "../../Constants/DeliveryMethod";
import { QuoteDeliveryMethodCode } from "../../Models/QuoteDeliveryMethod";
import { FlexContainer, FlexItem } from "../Flex/Flex.styled";
import { GetAmountTransactionAction } from "../../Redux/GetAmountTransaction/GetAmountTransaction.actions";
import { GET_AMOUNT_TRANSACTION_FULFILLED } from "../../Constants/Transaction";
import { useCountry } from "@hooks/Payments/useCountry";
import { isCountryWithCurrencyArray } from "@core/Payments/Common/domain/Countries";
import { useQuote } from "@hooks/Payments/useQuote";
import { QuotationSelector } from "@redux/Payments/Quotation/Quotation.slice";
import { ExchangeRateContainer } from "@components/Payments/QuoteSummary/QuoteSummary.styled";
import { QuoteTotals } from "@components/Payments/QuoteSummary/QuoteTotals.component";
import { FIRST_ELEMENT } from "@constants/SwitchButton";
import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";
import { KYC_APPROVED, KYC_STARTED } from "@constants/KYCStatus";
import { useModal } from "@hooks/Payments/useModal";
import Link from "@components/Link";
import Popover from "@components/Popover";
import { ChannelSwitch } from "@components/Payments/ChannelSwitch/ChannelSwitch.component";
import { useFeature } from "@hooks/Payments/useFeature";
import { bestFXLessFeePayers } from "@constants/Regexp";
import { QuoteChannel } from "@core/Payments/Quote/domain/QuoteSelected";
import { useDebounce } from "@hooks/useDebounce";

const GlobalPaymentsQuoter = ({
  countryDestination,
  amount,
  kycStatus,
  kycLevel,
  sponsorBank,
  countrySubdivision,
  setVerifyIdentity,
  handleShowNotification,
  onKYCLevel2Request,
}: QuoterProps) => {
  const [t] = useTranslation("global");
  const [tSendMoney] = useTranslation("sendMoney");
  const [tModals] = useTranslation("modals");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lang = useSelector(selectorLanguage);
  const {
    currentQuotation,
    currentDeliveryMethodQuote,
    currentPaymentDestination,
    currentPaymentMethod,
    currentChannel,
    quotesPerChannel,
  } = useSelector(QuotationSelector).data;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [deliveryMethod, setActiveDeliveryMethod] = useState<QuoteDeliveryMethodCode>("D2B");
  const currencyDefault = useRef("MXN");
  const [, setD2BOptions] = useState<DestinationOption[]>([]);
  const [CPUOptions, setCPUOptions] = useState<DestinationOption[]>([]);
  const [destinationCPU, setCPUDestination] = useState<OptionCPUCountry>();
  const [valueCurrency, setValueCurrency] = useState(currencyDefault.current);
  const [countryDestinationValue, setCountryDestinationValue] = useState(countryDestination);
  const [amountTyped, setAmountTyped] = useState(amount);
  // eslint-disable-next-line
  const [selectedCountryItem, setSelectedCountryItem] = useState<PropsValue<any>>();
  const [hasCashPickup, setHasCashPickup] = useState<boolean | undefined>();
  const [hasDirectToBank, setHasDirectToBank] = useState<boolean | undefined>();
  const [canShowTooltip, setCanShowTooltip] = useState(false);
  const [amountAvailable, setAmountAvailable] = useState(true);
  const { modal: legalNotesModal, showModal: showLegalNotesModal } = useModal();

  const minAmount = useRef<number>(5);
  const maxAmount = useRef<number>();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const {
    setValue,
    clearErrors,
    trigger,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const { setValue: setValue2, trigger: trigger2 } = useForm({
    mode: "onChange",
  });
  const { bestFxLessFeeFeature } = useFeature();
  const { countriesWithoutUSAOptions, getDestinationCountryList } = useCountry();
  const {
    quoterValue,
    isLoading: quoteIsLoading,
    velocityLimitsToShow,
    cantSubmit: cantSubmitQuotation,
    messagesVelocity,
    KYCLevel1LimitReached,
    quoteModal,
    handleQuotationChange,
    resetQuote,
  } = useQuote();

  const amountPerTransactionLimit = useMemo(
    () => velocityLimitsToShow.find((item) => item.code === "VL005" || item.code === "VL008"),
    [velocityLimitsToShow]
  );
  const amountLimit = useMemo(
    () => (amountPerTransactionLimit ? Number(amountPerTransactionLimit.value) : null),
    [amountPerTransactionLimit]
  );
  const maxExceeded = Boolean(
    (maxAmount.current && Number(amountTyped) > maxAmount.current) || (amountLimit && Number(amount) > amountLimit)
  );
  const minNotReached = Boolean(minAmount.current && Number(amountTyped) < minAmount.current);
  const amountNotValid = Boolean(amountTyped) && (maxExceeded || minNotReached);

  const cantSubmit =
    !amountTyped ||
    !currentQuotation ||
    !currentDeliveryMethodQuote ||
    !countryDestination ||
    amountNotValid ||
    cantSubmitQuotation ||
    !quoterValue.current.currentQuote ||
    (kycStatus !== KYC_STARTED && kycStatus !== KYC_APPROVED);

  const showChannelSwitch = useMemo(
    () =>
      bestFxLessFeeFeature &&
      currentDeliveryMethodQuote &&
      Number(currentQuotation?.amount || "0") >= 400 &&
      currentPaymentDestination?.payerQuote &&
      bestFXLessFeePayers.test(currentPaymentDestination.payerQuote),
    [
      currentDeliveryMethodQuote,
      currentQuotation,
      currentPaymentDestination?.payerQuote,
      currentPaymentMethod,
      bestFxLessFeeFeature,
    ]
  );

  const GetAllCountries = async () => {
    setIsLoading(true);
    const response = await getDestinationCountryList(lang.language, sponsorBank);

    if (isCountryWithCurrencyArray(response)) {
      const countryOptions = response.map((c) => ({
        label: c.defaultCurrency,
        value: c.countryCode,
        shortCode: c.alpha2Code,
        image: c.icon,
        shortLabel: c.defaultCurrency,
        defaultCurrency: c.defaultCurrency,
      }));
      const defaultCountry =
        countryOptions.find((c) => {
          if (countryDestination === "USA") {
            return c.value === "MEX";
          } else {
            return c.value === countryDestination;
          }
        }) ||
        countryOptions.find((c) => c.value === "MEX") ||
        countryOptions[0];
      currencyDefault.current = defaultCountry?.defaultCurrency;

      setSelectedCountryItem(defaultCountry);
      setCountryDestinationValue(defaultCountry.value);
      setValueCurrency(defaultCountry.defaultCurrency);
      setIsLoading(false);
      setIsError(false);

      return { countries: response, defaultCountry };
    } else {
      setIsLoading(false);
      setIsError(true);
    }

    return { countries: [], defaultCountry: null };
  };

  const GetBanksAndLocationsGPByUser = async (
    type: QuoteDeliveryMethodCode,
    countryCode: string,
    _currency: string,
    setListOptions: (list: DestinationOption[]) => void,
    setValueSelect?: (value: OptionD2BCountry) => void
  ) => {
    setIsLoading(true);
    if (setValueSelect) {
      setValueSelect({
        value: "",
        label: "",
      });
    }

    const payload = {
      type: type,
      countryCode,
      sponsorBank,
    };

    const response = await dispatch(getLocationsGPAction(payload));

    if (response?.type === LOCATIONS_GP_FULFILLED) {
      const list = unwrapResult(response)?.map((location) => ({
        value: location.id,
        label: location.destination,
        ...location,
      }));
      setListOptions(list);
      setIsLoading(false);
      setIsError(false);
      if (type === D2B && list && list.length > 0) {
        setHasDirectToBank(list && list.length > 0);
        setValue("DirectToBankSelected", list[0]);
        trigger("DirectToBankSelected");

        minAmount.current = list[0].minimumSendAmount || 5;
        maxAmount.current = list[0].maximumSendAmount;
      } else if (type === CPU) {
        const deliveryHashCashPickup = list && list.length > 0;

        setHasCashPickup(deliveryHashCashPickup);
        setCanShowTooltip(!deliveryHashCashPickup);
        if (deliveryHashCashPickup) {
          setCPUDestination(list[0]);
          setValue2("CashPickUpSelected", list[0]);
          trigger2("CashPickUpSelected", { shouldFocus: true });
        }
      }

      return list;
    }

    if (response?.type === LOCATIONS_GP_REJECTED) {
      setListOptions([]);
      setIsLoading(false);
      if (type === CPU) {
        return setHasCashPickup(false), setCanShowTooltip(true);
      } else if (type === D2B) {
        return setHasDirectToBank(false), setCanShowTooltip(true);
      }
    }
    return [];
  };

  const onSubmit = async () => {
    if (!amountTyped || Number(amountTyped) < minAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.minimumAmountError", {
          amount: formatNumber(minAmount.current),
        }),
      });
      return;
    }

    setIsLoading(true);
    setIsError(false);

    if (kycStatus === KYC_STARTED && setVerifyIdentity) {
      setVerifyIdentity(true);
      setIsLoading(false);
    }

    if (kycStatus === KYC_APPROVED) {
      if (countrySubdivision === "PR") {
        return handleShowNotification && handleShowNotification();
      }
      navigate("/beneficiary-selection");
    }
  };

  const changeChannel = async (channel: QuoteChannel) => {
    handleQuotationChange({ ...quoterValue.current, channel });
  };

  const getQuote = async (
    amount: number,
    country: string,
    currency: string,
    deliveryMethod?: QuoteDeliveryMethodCode,
    sendTo?: string,
    channel?: QuoteChannel
  ) => {
    const payload = {
      amount,
      country,
      currency,
      sendTo,
      deliveryMethod,
      channel,
    };

    await handleQuotationChange(payload);
  };

  const HandleChangeCountry = async (option: SelectOption) => {
    const d2bOptions = await GetBanksAndLocationsGPByUser(D2B, option.value, option.shortLabel!, setD2BOptions);
    const cpuOptions = await GetBanksAndLocationsGPByUser(
      CPU,
      option.value,
      option.shortLabel!,
      setCPUOptions,
      setCPUDestination
    );

    UpdateQuote(
      deliveryMethod,
      deliveryMethod === "CPU" ? cpuOptions?.[0]?.id : d2bOptions?.[0]?.id,
      option.value,
      option.shortLabel
    );
  };

  const UpdateQuote = (
    deliveryMethod?: QuoteDeliveryMethodCode,
    sendTo?: string,
    country?: string,
    currency?: string
  ) => {
    handleQuotationChange({
      ...quoterValue.current,
      amount: amountTyped,
      currency: currency || valueCurrency,
      country: country || countryDestinationValue,
      deliveryMethod,
      sendTo,
    });
  };

  const initializeData = async () => {
    const { defaultCountry } = await GetAllCountries();
    if (defaultCountry) {
      await GetBanksAndLocationsGPByUser(D2B, defaultCountry.value, currencyDefault.current, setD2BOptions);
      await GetBanksAndLocationsGPByUser(CPU, defaultCountry.value, currencyDefault.current, setCPUOptions);
    }
  };

  const GetAmount = async () => {
    const response = await dispatch(GetAmountTransactionAction("0"));

    if (response.type === GET_AMOUNT_TRANSACTION_FULFILLED) {
      if (kycLevel === 1 && response?.payload.data.totalAmount === 999) {
        setAmountAvailable(false);
      }
    }
  };

  useEffect(() => {
    initializeData().then(async () => {
      await GetAmount();
      await resetQuote();
    });
  }, []);

  const handleChangeAmount = useDebounce((value: number) => {
    clearErrors("amount");
    setValue("amount", value ? value.toString() : 0);
    if (!value || Number(value) < minAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.minimumAmountError", {
          amount: formatNumber(minAmount.current),
        }),
      });
      return;
    }
    if (maxAmount.current && value && Number(value) > maxAmount.current) {
      setError("amount", {
        type: "custom",
        message: t("HomePage.GlobalPaymentsQuoter.maximumAmountError", {
          amount: formatNumber(maxAmount.current),
        }),
      });
      return;
    }
    trigger("amount");

    const destination = deliveryMethod === "CPU" ? destinationCPU?.value : undefined;
    getQuote(value, countryDestinationValue, valueCurrency, deliveryMethod, destination);
  }, 1000);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (tooltipRef.current && !tooltipRef.current?.contains(event.target as Node)) {
        setCanShowTooltip(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <>
      <FlexContainer as={QuoterCard} gap="12px" direction="column">
        <FlexContainer direction="column">
          <Row>
            <Column span={12}>
              <Text size={0.3} color="neutral700" align="left" weight="500" margin="0">
                {t("HomePage.GlobalPaymentsQuoter.AmountSendTitle")}
              </Text>
            </Column>
            <Column span={6} alignItems={AlignItems.baseline} justifyContent={JustifyContent.flexStart}>
              <CurrencyInput
                id="amount"
                label="amount"
                name="amount"
                value={amountTyped}
                defaultValue={amount > 0 ? amount : null}
                placeholder="0.00"
                align="left"
                smallPadding
                noBorder
                allowDecimals
                maxLength={5}
                rules={{
                  required: t("Forms.required"),
                }}
                customError={Boolean(errors["amount"])}
                onValueChange={(value: number) => {
                  setAmountTyped(value);
                  handleChangeAmount(value);
                }}
              />
            </Column>
            <Column span={6} alignItems={AlignItems.baseline} justifyContent={JustifyContent.flexEnd}>
              <SectionFlag>
                <GlobalPaymentIconRounded>
                  <Icon icon="usaFlagCircle" size="medium" />
                </GlobalPaymentIconRounded>
                <Text size={1} color="black" align="left" weight="500" margin="0">
                  USD
                </Text>
              </SectionFlag>
            </Column>
          </Row>
          <DivisorLine />
          {errors && errors["amount"] && (
            <FlexContainer m="5px 0" gap="5px">
              <Icon icon="circleInfo" color="error" size="xsmall" fillIcon={false} />
              <Text color="error" weight={500} size={0.2} align="left" margin={0}>
                {errors["amount"].message?.toString()}
              </Text>
            </FlexContainer>
          )}
          {!isEmpty(velocityLimitsToShow) && !KYCLevel1LimitReached && (
            <FlexContainer m="5px 0" alignItems="center" gap="5px">
              <Icon icon="circleInfo" color="error" size="xsmall" fillIcon={false} />
              <Text color="error" weight={500} size={0.3} align="left" margin={0}>
                <span dangerouslySetInnerHTML={{ __html: messagesVelocity[velocityLimitsToShow[0]?.code] }}></span>
              </Text>
            </FlexContainer>
          )}
          {KYCLevel1LimitReached && (
            <FlexContainer m="8px 0" alignItems="center" gap="5px">
              <Icon icon="alertTriangle" color="warning" size="small" />
              <Text size={0.3} align="left" margin={0}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: tModals("velocityLimits.messageKYC1Resubmission", {
                      href: "#",
                    }),
                  }}
                />
                <Link href="#" variant="primaryBlue400" size={0.3} onClick={onKYCLevel2Request}>
                  {tModals("velocityLimits.messageKYC1ResubmissionClick")}
                </Link>
              </Text>
            </FlexContainer>
          )}
          <Row>
            <Column span={12}>
              <Text size={0.3} color="neutral700" align="left" weight="500" margin="0">
                {t("HomePage.GlobalPaymentsQuoter.AmountRecipientsTitle")}
              </Text>
            </Column>
            <Column
              span={6}
              alignItems={AlignItems.baseline}
              justifyContent={JustifyContent.flexStart}
              as={SectionRecipientGets}
            >
              {isLoading || quoteIsLoading ? (
                <Skeleton width={60} height={25} />
              ) : (
                <Text size={1.5} color="gradientPrimaryBlue" align="left" weight="600" margin="0" gradient>
                  {formatNumber(quoterValue.current.currentQuote?.amountToReceive || 0)}
                </Text>
              )}
            </Column>

            <Column span={6} alignItems={AlignItems.baseline} justifyContent={JustifyContent.flexEnd}>
              <SectionFlagWithSelect>
                {isLoading ? (
                  <Skeleton width={60} height={25} />
                ) : (
                  <>
                    <SearchSelect
                      labelProps={{ color: "solid_light", size: 0 }}
                      options={countriesWithoutUSAOptions}
                      name="currency"
                      value={selectedCountryItem}
                      showAvatar
                      showAvatarOnValue
                      customStyles={currencySelectRTLStyles}
                      m="0"
                      onChange={(option: SelectOption) => {
                        setCountryDestinationValue(option.value);
                        setValueCurrency(option.shortLabel!);
                        setSelectedCountryItem({
                          ...option,
                          label: option.shortLabel,
                        });
                        return HandleChangeCountry(option);
                      }}
                    />
                  </>
                )}
              </SectionFlagWithSelect>
            </Column>

            <Column span={12} p={0} alignItems={AlignItems.flexStart}>
              <Link
                href="#"
                size={0.1}
                variant="neutral600"
                underline
                onClick={() => {
                  showLegalNotesModal({
                    modalType: "legalInfoModal",
                  });
                }}
              >
                {tSendMoney("KnowSendingLimits")}
              </Link>
            </Column>
          </Row>
        </FlexContainer>
        {quoterValue.current.currentQuote?.fx && (
          <FlexContainer direction="column">
            <FlexContainer alignItems="center" justify="space-between" p="2px 8px 4px 8px" as={ExchangeRateContainer}>
              <Text size={0.3} lineHeight="21px" align="left" color="white" weight={600} margin={0}>
                {tSendMoney("ExchangeRate")}:
              </Text>
              <FlexItem alignItems="center" justify="center" gap="5px">
                {isLoading || quoteIsLoading ? (
                  <Skeleton width={120} height={20} margin="0" />
                ) : (
                  <Text size={0.3} lineHeight="21px" align="right" color="white" weight={600} margin={0}>
                    $1 USD = $
                    {formatNumber(quoterValue.current.currentQuote?.fx, {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}{" "}
                    {currentQuotation?.destinationCurrency}
                  </Text>
                )}
                <Popover content={tSendMoney("ExchangeRateDisclosure")} contentSize={291} trigger="hover" align="right">
                  <Icon icon="circleInfoBlack" size="small" color="white" />
                </Popover>
              </FlexItem>
            </FlexContainer>
          </FlexContainer>
        )}
        <Row as={currentDeliveryMethodQuote?.fx && valueCurrency ? marginBottomContainer : marginVerticalContainer}>
          <Column span={12}>
            <SwitchButton
              label1={t("HomePage.GlobalPaymentsQuoter.label1")}
              label2={t("HomePage.GlobalPaymentsQuoter.label2")}
              icon1="bank"
              icon2="cash"
              hasCPU={hasCashPickup}
              hasD2B={hasDirectToBank}
              GetActive={(active: string) => {
                const method = active === FIRST_ELEMENT ? "D2B" : "CPU";
                const destination = method === "CPU" ? destinationCPU?.value || CPUOptions[0].id : undefined;
                setActiveDeliveryMethod(method);
                UpdateQuote(method, destination);
              }}
            />
            <div ref={tooltipRef}>
              <DarkTooltip
                show={canShowTooltip}
                hasCPU={hasCashPickup}
                hasShadow={true}
                textInfo={t(
                  hasDirectToBank
                    ? "HomePage.GlobalPaymentsQuoter.cpuNotAvailable"
                    : "HomePage.GlobalPaymentsQuoter.d2bNotAvailable"
                )}
              />
            </div>
          </Column>
        </Row>
        {deliveryMethod === "CPU" && (
          <Row>
            <Column span={12}>
              <Select
                options={CPUOptions}
                name="CashPickUpSelected"
                placeholder={t("HomePage.GlobalPaymentsQuoter.selectLocation")}
                styles={selectStylesGlobalPayments}
                components={{
                  IndicatorSeparator: () => null,
                  NoOptionsMessage: () => {
                    return <NotEmptyOptions>{t("HomePage.GlobalPaymentsQuoter.notEmptyLocations")}</NotEmptyOptions>;
                  },
                }}
                // eslint-disable-next-line
                onChange={(option: any) => {
                  UpdateQuote("CPU", option.id);
                  return setCPUDestination(option);
                }}
                value={destinationCPU}
              />
            </Column>
          </Row>
        )}
        <FlexContainer>
          {Number(currentQuotation?.amount || 0) >= 400 &&
            showChannelSwitch &&
            currentQuotation?.destinationCurrency && (
              <FlexContainer direction="column" alignItems="start" gap="8px">
                <FlexContainer direction="column">
                  <Text align="left" color="neutral700" size={1.5} weight={500} margin={0}>
                    {tSendMoney("PaymentMethodSelection.chooseDeal")}
                  </Text>
                  <Text align="left" color="neutral500" size={0.3} weight={500} margin={0}>
                    <span
                      dangerouslySetInnerHTML={{ __html: tSendMoney("PaymentMethodSelection.chooseDealDescription") }}
                    ></span>
                  </Text>
                </FlexContainer>
                <ChannelSwitch
                  value={currentChannel}
                  lessFeesData={{
                    fx: quotesPerChannel?.[QuoteChannel.lessFee]?.currentDeliveryMethodQuote?.fx || 0,
                    fee: quotesPerChannel?.[QuoteChannel.lessFee]?.currentDeliveryMethodQuote?.fee || 0,
                    currency: currentQuotation?.destinationCurrency,
                  }}
                  bestFXData={{
                    fx: quotesPerChannel?.[QuoteChannel.bestFx]?.currentDeliveryMethodQuote?.fx || 0,
                    fee: quotesPerChannel?.[QuoteChannel.bestFx]?.currentDeliveryMethodQuote?.fee || 0,
                    currency: currentQuotation?.destinationCurrency,
                  }}
                  onChange={changeChannel}
                />
              </FlexContainer>
            )}
        </FlexContainer>
        <QuoteTotals
          transferFee={currentDeliveryMethodQuote?.fee || 0}
          paymentMethodFee={currentPaymentMethod?.paymentMethodFee || 0}
          total={currentDeliveryMethodQuote?.paymentMethods?.[0]?.totalCost || 0}
        />

        {isError && (
          <Column span={12} mt={1}>
            <Text size={0.5} align="center" weight={600} color="error">
              {t("404.subtitle")}
            </Text>
          </Column>
        )}

        <FlexContainer justify="center">
          <Button
            variant={
              isLoading || !amountAvailable || !isEmpty(velocityLimitsToShow) || cantSubmit ? "default" : "gradient"
            }
            bgGradient={{
              orientation: "horizontal",
              firstColorStop: "#7e22ce",
              secondColorStop: "#3730a3",
            }}
            textColor="white"
            disabled={isLoading || !amountAvailable || !isEmpty(velocityLimitsToShow) || cantSubmit}
            text={t("HomePage.GlobalPaymentsQuoter.btnSend")}
            fontWeight={500}
            sizeText="small"
            sizeButton="medium"
            onClick={() => onSubmit()}
          />
        </FlexContainer>

        <Column span={12}>
          <InfoMessage>
            <Icon icon="circleInfoBlack" size="small-2" color="neutral600" />
            <CustomText tagName="p" color={"neutral600"} align="left" size={0.1} margin={0} weight={400}>
              {t("HomePage.GlobalPaymentsQuoter.quoteVariationMessage")}
              <ViewLegalInformationLink
                href="#"
                size={0.1}
                align="left"
                color="Primary01"
                underline
                onClick={() => {
                  showLegalNotesModal({
                    modalType: "legalInfoModal",
                  });
                }}
              >
                {t("HomePage.GlobalPaymentsQuoter.quoteVariationMessageLegalInformation")}
              </ViewLegalInformationLink>
            </CustomText>
          </InfoMessage>
        </Column>
        {legalNotesModal}
        {quoteModal}
      </FlexContainer>
    </>
  );
};

export default GlobalPaymentsQuoter;
