import { createClient } from "@services/API/GlobalPaymentsApiClient";
import { QuoteDeliveryMethodCode } from "../../Quote/domain/QuoteDeliveryMethod";
import { AdditionalField, AdditionalFieldsPayload } from "../domain/AdditionalFields";
import { Destination, DestinationBranch, DestinationBranchPayload } from "../domain/Destination";
import { DestinationRepository } from "../domain/DestinationRepository";

const client = createClient();
const destinationPrefixService = "gpcatalogs/destinations";

export const createApiDestinationRepository = (): DestinationRepository => {
  return {
    getDestinations,
    getBranches,
    getAdditionalFields,
    getFieldsAppriza,
  };
};

const getDestinations = async (deliveryMethod: QuoteDeliveryMethodCode, country: string, sponsorBank?: string) => {
  const response = await client.get<Destination[]>(
    `${destinationPrefixService}/${deliveryMethod}${
      import.meta.env.VITE_FILTER_UNITELLER_DESTINATIONS === "true" ? "-uniteller" : ""
    }/${country}`,
    { headers: { sponsorBank } }
  );

  return response.data;
};

const getBranches = async (payload: DestinationBranchPayload) => {
  const response = await client.get<DestinationBranch[]>(`${destinationPrefixService}/DestinationBranches`, {
    params: {
      PayerSpecificCode: payload.payerSpecificCode,
      CountryISOCode: payload.countryISOCode,
      CurrencyISOCode: payload.currencyISOCode,
      ReceptionMethodName: payload.receptionMethodName,
      StateISOCode: payload.stateISOCode,
      City: payload.city,
    },
  });

  return response.data;
};

const getAdditionalFields = async ({
  currencyISOCode,
  payerSpecificCode,
  destinationId,
  language,
  receptionMethodName,
}: AdditionalFieldsPayload) => {
  const response = await client.get<AdditionalField[]>(`${destinationPrefixService}/DestinationsAditionalFields`, {
    params: {
      CurrencyISOCode: currencyISOCode,
      PayerSpecificCode: payerSpecificCode,
      DestinationId: destinationId,
      language: language,
      ReceptionMethodName: receptionMethodName,
    },
  });

  return response.data;
};

const getFieldsAppriza = async (countryCode: string, language?: string) => {
  const response = await client.get<AdditionalField[]>(
    `${destinationPrefixService}/additional-fields-appriza/${countryCode}`,
    {
      headers: {
        languageCode: language,
      },
    }
  );

  return response.data;
};
