import { FlexContainer } from "@components/Flex/Flex.styled";
import { VerifyKYCBannerCard } from "./VerifyKYCBanner.styled";
import VerifyAlert from "@assets/Img/identity-verify.png";
import Text from "@components/Text";
import { SendolaSecondaryColorSpan } from "@components/Text/Text.styled";
import { useTranslation } from "react-i18next";
import Button from "@components/Payments/Button";

interface VerifyKYCBannerProps {
  onVerify: () => void;
}

export const VerifyKYCBanner = ({ onVerify }: VerifyKYCBannerProps) => {
  const [t] = useTranslation("global");

  return (
    <VerifyKYCBannerCard>
      <img src={VerifyAlert} width={73} />
      <FlexContainer flex="1 1 auto" justify="center" alignItems="center" gap="8px" direction="column">
        <Text size={1} desktop={2} align="center" weight={700}>
          <SendolaSecondaryColorSpan dangerouslySetInnerHTML={{ __html: t("VerifyKYCBanner.title") }} />
        </Text>
        <Text size={0.2} desktop={0.5} color="primary" weight={500}>
          {t("VerifyKYCBanner.description")}
        </Text>
        <Button variant="primary" sizeButton="fit" padding="8px 20px" onClick={onVerify}>
          <Text size={0.2} desktop={0.5} color="white" weight={600}>
            {t("VerifyKYCBanner.submit")}
          </Text>
        </Button>
      </FlexContainer>
    </VerifyKYCBannerCard>
  );
};
