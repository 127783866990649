import { PaymentOrderStatus } from "@core/Payments/PaymentOrders/domain/PaymentOrder";
import styled from "styled-components";
import { theme } from "styled-tools";

export const CardDetails = styled.div`
  background-color: ${theme("Background_01")};
  border-bottom: 1px solid ${theme("Background_09")};
  width: 100%;
  max-width: 545px;
  margin: 12px 0px;
  cursor: pointer;
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: center;
  gap: auto;
  width: 100%;
  padding: 12px 0;
  margin: 0;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 8px;
  width: auto;
  align-items: center;
`;

export const IconCountry = styled.div`
  display: flex;
  align-items: center;
`;

export const ContactDetails = styled.div`
  font-family: Poppins;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;
export const StatusText = styled.p<{ status: string }>`
  font-size: 12px;
  font-weight: 400;
  margin: "0 0 4px 0";
  text-align: left;
  color: ${theme("Content_UX_04")};
  span {
    font-weight: 600;
    color: ${(props) =>
      props.status.toLowerCase() === PaymentOrderStatus.paid ||
      props.status.toLowerCase() === PaymentOrderStatus.declined
        ? theme("Primary_12")
        : props.status.toLowerCase() === PaymentOrderStatus.rejected ||
            props.status.toLowerCase() === PaymentOrderStatus.canceled ||
            props.status.toLowerCase() === PaymentOrderStatus.notPaid
          ? theme("Primary_20")
          : props.status.toLowerCase() === PaymentOrderStatus.pending ||
              props.status.toLowerCase() === PaymentOrderStatus.verified ||
              props.status.toLowerCase() === PaymentOrderStatus.accepted ||
              props.status.toLowerCase() === PaymentOrderStatus.created ||
              props.status.toLowerCase() === PaymentOrderStatus.funds ||
              props.status.toLowerCase() === "reviewing" ||
              props.status.toLowerCase() === PaymentOrderStatus.review ||
              props.status.toLowerCase() === PaymentOrderStatus.inReview ||
              props.status.toLowerCase() === PaymentOrderStatus.available
            ? theme("Primary_21")
            : theme("Content_UX_04")};
  }
`;
